import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FuseUtils } from '@fuse/utils';

import { Client } from './client.model';
import { environment } from 'environments/environment';
import { Dropdown } from 'app/services/dropdown.model';
import { Paginator } from 'app/main/shared/pagination/paginator.model';

@Injectable()
export class ClientsService implements Resolve<any>
{
    onClientsChanged: BehaviorSubject<any>;
    onPaginatorChanged: BehaviorSubject<any>=new BehaviorSubject(null);
    onSelectedClientsChanged: BehaviorSubject<any>;
    // onUserDataChanged: BehaviorSubject<any>;
    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;

    clients: Client[];
    // user: any;
    selectedClients: number[] = [];

    searchText: string;
    filterBy: string;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    )
    {
        // Set the defaults
        this.onClientsChanged = new BehaviorSubject([]);
        this.onSelectedClientsChanged = new BehaviorSubject([]);
        // this.onUserDataChanged = new BehaviorSubject([]);
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getClients(),
                // this.getUserData()
            ]).then(
                ([files]) => {

                    this.onSearchTextChanged.subscribe(searchText => {
                        this.searchText = searchText;
                        this.getClients();
                    });

                    this.onFilterChanged.subscribe(filter => {
                        this.filterBy = filter;
                        this.getClients();
                    });

                    resolve(files);

                },
                reject
            );
        });
    }

    /**
     * Get clients
     *
     * @returns {Promise<any>}
     */
    getClients(search=''): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.serverURL}/api/clients${search}`)
                .subscribe((response: any) => {

                        const clients = response["hydra:member"];
                        this.onPaginatorChanged.next(new Paginator(response["hydra:view"], this, response["hydra:totalItems"],'getClients'));

                        // .. search
                        if ( this.searchText && this.searchText !== '' )
                        {
                            this.clients = FuseUtils.filterArrayByString(this.clients, this.searchText);
                        }

                        this.clients = clients.map(client => {
                            return new Client(client);
                        });

                        this.onClientsChanged.next(this.clients);
                        resolve(this.clients);
                    }, reject);
            }
        );
    }

    /**
     * Toggle selected client by id
     *
     * @param id
     */
    toggleSelectedClient(id): void
    {
        // First, check if we already have that client as selected...
        if ( this.selectedClients.length > 0 )
        {
            const index = this.selectedClients.indexOf(id);

            if ( index !== -1 )
            {
                this.selectedClients.splice(index, 1);

                // Trigger the next event
                this.onSelectedClientsChanged.next(this.selectedClients);

                // Return
                return;
            }
        }

        // If we don't have it, push as selected
        this.selectedClients.push(id);

        // Trigger the next event
        this.onSelectedClientsChanged.next(this.selectedClients);
    }

    /**
     * Toggle select all
     */
    toggleSelectAll(): void
    {
        if ( this.selectedClients.length > 0 )
        {
            this.deselectClients();
        }
        else
        {
            this.selectClients();
        }
    }

    /**
     * Select clients
     *
     * @param filterParameter
     * @param filterValue
     */
    selectClients(filterParameter?, filterValue?): void
    {
        this.selectedClients = [];

        // If there is no filter, select all clients
        if ( filterParameter === undefined || filterValue === undefined )
        {
            this.selectedClients = [];
            this.clients.map(client => {
                this.selectedClients.push(client.id);
            });
        }

        // Trigger the next event
        this.onSelectedClientsChanged.next(this.selectedClients);
    }

    /**
     * Create client
     *
     * @param client
     * @returns {Promise<any>}
     */
    createClient(client): Promise<any>
    {
        // console.log('creating client');
        return new Promise((resolve, reject) => {

            this._httpClient.post(environment.serverURL+'/api/clients', {...client})
                .subscribe(response => {
                    this.getClients();
                    resolve(response);
                });
        });
    }

    /**
     * Update client
     *
     * @param client
     * @returns {Promise<any>}
     */
    updateClient(client): Promise<any>
    {
        // console.log('updating client');
        return new Promise((resolve, reject) => {

            this._httpClient.patch(environment.serverURL+'/api/clients/' + client.id, {...client})
                .subscribe(response => {
                    this.getClients();
                    resolve(response);
                });
        });
    }

    /**
     * Deselect clients
     */
    deselectClients(): void
    {
        this.selectedClients = [];

        // Trigger the next event
        this.onSelectedClientsChanged.next(this.selectedClients);
    }

    /**
     * Delete client
     *
     * @param client
     * @returns {Promise<any>}
     */
    deleteClient(client): Promise<any>
    {
        // const clientIndex = this.clients.indexOf(client);
        // this.clients.splice(clientIndex, 1);

        let clientId = client;
        if ( typeof client === 'object' && client.id){
            clientId = client.id;
        }
        //////
        return new Promise((resolve, reject) => {
            // console.log(client);

            this._httpClient.delete(environment.serverURL+'/api/clients/' + clientId )
                .subscribe(response => {
                    this.getClients();

                    // this.onClientsChanged.next(this.clients);
                    resolve(response);
                });
        });
                //////
        // this.onClientsChanged.next(this.clients);
    }

    /**
     * Delete selected clients
     */
    deleteSelectedClients(): void
    {
        for ( const clientId of this.selectedClients )
        {
            // const client = this.clients.find(_client => {
            //     return _client.id === clientId;
            // });
            // const clientIndex = this.clients.indexOf(client);
            // this.clients.splice(clientIndex, 1);

            this.deleteClient(clientId);
        }
        // this.onClientsChanged.next(this.clients);
        this.deselectClients();
    }

}

@Injectable()
export class ClientsDropdownService implements Resolve<any>
{
    onClientsDropdownChanged: BehaviorSubject<any>;

    clientsDropdown: Dropdown[];

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    )
    {
        // Set the defaults
        this.onClientsDropdownChanged = new BehaviorSubject([]);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getClientsDropdown(),
            ]).then(
                ([files]) => {

                    // TODO: try to check if Server sent Event clients-updated socket
                    // this.onSearchTextChanged.subscribe(searchText => {
                    //     this.searchText = searchText;
                    //     this.getClients();
                    // });


                    resolve(files);

                },
                reject
            );
        });
    }

    /**
     * Get clients
     *
     * @returns {Promise<any>}
     */
    getClientsDropdown(): Promise<any>
    {
        return new Promise((resolve, reject) => {
                this._httpClient.get( environment.serverURL+'/api/clients/dropdown')
                    .subscribe((response: any) => {

                        const clientsDropdown = response["hydra:member"];

                        this.clientsDropdown = clientsDropdown.map((client:any) => {
                            return new Dropdown(client["@id"],client.name,client);
                        });

                        this.onClientsDropdownChanged.next(this.clientsDropdown);
                        resolve(this.clientsDropdown);
                    }, reject);
            }
        );
    }
}
