import { Injectable } from '@angular/core';
import * as moment from 'moment';

// import { ApiService } from './api.service';
import { isDefined } from '@angular/compiler/src/util';
// import { fillProperties } from '@angular/core/src/util/property';
import { BehaviorSubject, interval } from 'rxjs';
import { start } from 'repl';
import { FormBuilder, FormArray, FormControl, FormGroup } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { resolve } from 'url';
declare const XLSX;
class ImageSnippet {
  constructor(public src: string, public file: File) { }
}

declare const $: any;

@Injectable({
  providedIn: 'root'
})
export class GtoolsService {

  onToggleButtonLoaderChanged: BehaviorSubject<any>;
  constructor(
    // private _api: ApiService
    private _httpClient: HttpClient,
    private fb: FormBuilder
  ) { 
    this.onToggleButtonLoaderChanged = new BehaviorSubject([]);
  }

  // Gender dropdown
  genderList = [
    { 'value': 'MR', 'label': 'Mr.' },
    { 'value': 'MRS', 'label': 'Mrs.' },
    { 'value': 'MLE', 'label': 'Ms.' },
  ];

  
  formValidations = {Book: {field1: [new FormValidation('NotNull', 'This field is required')]}};

  processFile(key: string, imageInput: any, fileName: string) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {

      const selectedFile = new ImageSnippet(event.target.result, file);

      // this._api.uploadImage(key, selectedFile.file, fileName).subscribe(
      //   (res) => {

      //   },
      //   (err) => {

      //   })
    });

    reader.readAsDataURL(file);
  }

  showNotification(from: any, align: any, type: any, message: any): void {
    // const type = ['', 'info', 'success', 'warning', 'danger', 'rose', 'primary'];
    // TODO: implement 
  }

  // Format date of a column
  dateFormatter(params): {} {
    return moment(params.value, 'YYYY-MM-DD\THH:mm:ssP').format('DD/MM/YYYY');
  }

  // Format date of a column
  dateTimeFormatter(params): {} {
    return moment(params.value, 'YYYY-MM-DD\THH:mm:ssP').format('DD/MM/YYYY HH:mm');
  }

  // Format time of a column
  timeFormatter(params): {} {
    return moment(params.value, 'YYYY-MM-DD\THH:mm:ssP').format('HH:mm');
  }

  // Format date to include today for <= conditions
  todayIncludedFormatter(date): {} {
    return moment(date).format('YYYY-MM-DD\T23:59:59+01:00');
  }

  dbDatetoUtcFormatter(date){
    return moment(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');  // Example date
    // return moment(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');  // Example date
  }
  dbDateOnlyToUtcFormatter(date): {} {
    return moment(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  }
  dbTimeToUtcFormatter(time): {} {
    return moment(time,'HH:mm',true).utc().format('HH:mm');
    // return moment(time,'HH:mm',true).utc().format('HH:mm');
    // return moment(time,'HH:mm:SS',true).format('HH:mm:SS');
  }

  // Format date for DB insert
  dbDateFormatter(date): {} {
    // console.log(date)
    // debugger
    //TODO: CONFIRM IMPLICATION, Server time,,
    return moment(date).format('YYYY-MM-DD\THH:mm:ss+01:00');
    // return moment(date).format('YYYY-MM-DD\THH:mm:ss+00:00');
  }
  dbDateOnlyFormatter(date): {} {
    return moment(date).format('YYYY-MM-DD');
  }

  // Format date for DB insert
  dbTimeFormatter(time): {} {
    // console.log(time);
    return moment(time,'HH:mm:SS',true).format('HH:mm:SS+01:00');
    // return moment(time,'HH:mm:SS',true).format('HH:mm:SS');
  }

  // Format boolean true or false
  boolFormatter(params): string {
    let formattedCell = '<span class="text-danger"><i class="material-icons my-2">close</i></span>';
    if (params.value) {
      formattedCell = '<span class="text-success"><i class="material-icons my-2">check</i></span>';
    }
    return formattedCell;
  }

  // Download from server
  downloadFile(data: any, type: string): void {
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    const pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  }

  specialCharacterValidator(control: FormControl) {
    const specialCharacterPattern = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/;
    if (specialCharacterPattern.test(control.value)) {
      return { hasSpecialCharacter: true };
    }
    return null;
  }



  // Download ticket
  downloadReceipt(id): void {
    // this._api.getPDFReceipt(id).subscribe(
    //   data => this.downloadFile(data, 'application/pdf'),
    //   err => console.log(err)
    // );
  }


  public static compareDate(date1: Date, date2: Date): number {
    // With Date object we can compare dates them using the >, <, <= or >=.
    // The ==, !=, ===, and !== operators require to use date.getTime(),
    // so we need to create a new instance of Date with 'new Date()'
    const d1 = new Date(date1); const d2 = new Date(date2);

    // Check if the dates are equal
    const same = d1.getTime() === d2.getTime();
    if (same) { return 0; }

    // Check if the first is greater than second
    if (d1 > d2) { return 1; }

    // Check if the first is less than second
    if (d1 < d2) { return -1; }
  }




  // Set configured fields to uppercase
  formatEditedFieldsValue(form): any {
    const fieldsName = ['name'];

    for (const field in form) {

      // Fields value to uppercase
      if (fieldsName.includes(field)) {
        form[field] = form[field].toUpperCase();
      } else {
        // console.log('NOP');
      }
    }
    return form;
  }

  // Get only changed form fields
  // TODO: fix formGroup inside a formGroup condition not considered. e.g schedule.pnl 
  getChangedFormFields(form: FormGroup,_includeAll=false): any {
    const fb = this.fb;
    const group = fb.group({});

    const isNew = (form.controls.id !== undefined && form.controls.id.value >0)?false:true;
    const includeAll = isNew || _includeAll;

    // debugger;
    // For 'Update' or 'View'
    if (form.controls['id'] !== undefined) {
      group.addControl('id', form.controls['id']);
    }
    if (form.controls['iri'] !== undefined) {
      group.addControl('iri', form.controls['iri']);
    }
    // TODO: find better solu for sn
    if (form.controls['sn'] !== undefined) {
      group.addControl('sn', form.controls['sn']);
    }
    // if (form.controls['image_upload'] !== undefined) {
    //   group.addControl('image_upload', form.controls['image_upload']);
    // }
    Object.keys(form.controls).forEach(name => {
      let currentControl: any = form.controls[name];
      // For FormArray instead of Formgroup
      if (currentControl instanceof FormArray){
        let _array = fb.array([]);
        Object.keys(currentControl.controls).forEach(i => {
          const _group = fb.group({});
          const _formGroup: any = currentControl.controls[i];
          // For 'Update' or 'View'
          // const _isNew = _formGroup.controls.id !== undefined && _formGroup.controls.id.value >0;
          // if (isNew) {
            _array.push(this.getChangedFormFields(_formGroup,includeAll));
          // }else{
          //   _array.push(_formGroup);
          // }
        });
        group.addControl(name, _array);
      }else{
        if (includeAll || currentControl.dirty) {
          // debugger;

          // if(moment(currentControl.value, 'YYYY-MM-DDTHH:mm:ssZ',true).format() !== 'Invalid date') {
          //   currentControl.value = currentControl.value;
          // }else
          // if(moment(currentControl.value, 'YYYY-MM-DD HH:mm:ss', true).format() !== 'Invalid date') {
          //   currentControl.value = this.dbDatetoUtcFormatter(currentControl.value);
          // // }else 


          //Tested: seems this work with all date format 'YYYY-MM-DD HH:mm:ss'||'YYYY-MM-DDTHH:mm:ssZ'||'YYYY-MM-DD'
          let inputValue = currentControl.value;//includeAll?currentControl.controls.value:currentControl.value;
          if(moment(currentControl.value, 'YYYY-MM-DDTHH:mm:ss',true).format() !== 'Invalid date') {
            currentControl.value = this.dbDatetoUtcFormatter(currentControl.value);
          }else
          if(moment(inputValue, 'YYYY-MM-DD',true).format() !== 'Invalid date') {
            currentControl.value = this.dbDateOnlyToUtcFormatter(inputValue);
          }else 
          if (moment(inputValue, 'HH:mm', true).format() != 'Invalid date'){
            currentControl.value = this.dbTimeToUtcFormatter(inputValue);
          }


          // if(moment(currentControl.value, 'YYYY-MM-DD HH:mm:SS', true).format() !== 'Invalid date') {
          //     currentControl.value = this.dbDatetoUtcFormatter(currentControl.value);
          // }else if(moment(currentControl.value, 'YYYY-MM-DD', true).format() !== 'Invalid date') {
          //   currentControl.value = this.dbDateOnlyFormatter(currentControl.value);
          // }
          // If is Date then convert for Database
          // if (moment(currentControl.value, 'YYYY-MM-DD', true).format() !== 'Invalid date') {
          //   // The input represents only the date (day without time)
          //   debugger;

          //   currentControl.value = this.dbDatetoUtcFormatter(currentControl.value);
          //   // currentControl.value = this.dbDateOnlyFormatter(currentControl.value);
          // }
          // if (moment(currentControl.value, 'YYYY-MM-DD HH:mm:SS', true).format() !== 'Invalid date') {
          //   debugger
          //   currentControl.value = this.dbDateFormatter(currentControl.value);
          // }else if (moment(currentControl.value, 'HH:mm:SS', true).format() != 'Invalid date') {
          //   debugger
          //   currentControl.value = this.dbTimeFormatter(currentControl.value);
          // }else 
          // // TODO: If is Time ..
          // // console.log('MOMEN',moment(currentControl.value, 'HH:mm', true).format());
          // // if (moment(currentControl.value, 'HH:mm:SS', true).format() != 'Invalid date') {
          // //   currentControl.value = this.dbTimeFormatter(currentControl.value);
          // // }

          group.addControl(name, currentControl);
        }
      }
    });
    // console.log('RESULT',group.getRawValue())
    return group;
  }

  // Compare field with dropdown
  compareSelect(v1: any, v2: any): boolean {
    return v1 && v2 ? v1.value === v2.id : v1 === v2;
  }
  compareSelectID(v1: any, v2: any): boolean {
    return v1 && v2 ? v1.id === v2.id : v1 === v2;
  }

  // Get timeout for call
  getTimeout(startDate, slaType): any {
    const now = moment();
    if (slaType === '4H') {
      slaType = 4 * 3600;
    } else if (slaType === '24H') {
      const today = moment().format('dd');
      slaType = 24 * 3600;
      if (today === 'Sa') {
        slaType = 48 * 3600;
      }
    } else {
      return 0;
    }
    const limite = moment(startDate);

    const seconds = slaType - now.diff(limite, 's');

    const format = Math.floor(moment.duration(seconds, 'seconds').asHours()) + 'h ' + moment.duration(seconds, 'seconds').minutes() + 'm ' + moment.duration(seconds, 'seconds').seconds() + 's';

    return format;
  }


  getOnlyUnique(value, index, self): any { 
    return self.indexOf(value) === index;
  }

  camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  
  snakeToCamelCase = str => str.replace(
    /([-_][a-z])/g,
    (group) => group.toUpperCase()
                    .replace('-', '')
                    .replace('_', '')
  )

  objectCamelToSnake(obj): {} {
    const objResult = {};
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const element = obj[key];
            objResult[this.camelToSnakeCase(key)] = element;
        }
    }
    return objResult;
  }

  objectSnakeToCamel(obj): {} {
    let objResult = {};
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const element = obj[key];
            objResult[this.snakeToCamelCase(key)] = element;
        }
    }
    return objResult;
  }


  /**
   * Get form validations for entity
   *
   * @returns {Promise<any>}
   */
  getFormValidations(entity): Promise<any> {
    if (!this.formValidations[entity]){
      return new Promise(( resolve, reject ) => {
        this._httpClient.get(`${environment.serverURL}/api/validations/${entity}`)
          .subscribe((response: any) => {
              // console.log('Form Validations', response);
              const validations = response;
              response.forEach(validation => {
                validations[validation.field] = validation.rules.map(rule => {
                    return new FormValidation(rule.constraintType, rule.message);
                  });
              });
              this.formValidations[entity] = validations;
              
              // [{field: 'code', rules: [{constraintType:'NotNull', message: 'This value should not be null.'}]}]
              resolve(this.formValidations[entity]);
          }, reject);
      });
    }
    return this.formValidations[entity];
  }

  getFieldValidation(entity, field): any {
    return this.formValidations[entity][field];
  }

  getFieldValidationDescription(entity, field): string {
    let mergedMessages = '';
    this.formValidations[entity][field].forEach(element => {
      mergedMessages += `${element.message} `;
    });
    return mergedMessages;
  }

  isFieldRequired(entity, field): boolean {
    if (this.formValidations[entity][field].map(validation => validation.constraintType === 'NotNull').length > 0){
      return true;
    }
    return false;
  }

  loadingButtons: any[] = [];
  /**
   * Toggle selected pnr by id
   *
   * @param id
   */
  toggleButtonLoader(id: string | number, status: boolean): void {
      // First, check if we already have that button item id is already loading...
      if (status === false) {
        if (this.loadingButtons.length > 0 ) {
            const index = this.loadingButtons.indexOf(id);
  
            if (index !== -1) {
                this.loadingButtons.splice(index, 1);
                // console.log('finished loading button', this.loadingButtons);
                // Trigger the next event
                this.onToggleButtonLoaderChanged.next(this.loadingButtons);
  
                // Return
                return;
            }
        }
      }else{
        const index = this.loadingButtons.indexOf(id);
        if (index == -1) {
          // If we don't have it, push as selected
          this.loadingButtons.push(id);
          // console.log('button loader added', this.loadingButtons);
          // Trigger the next event
          this.onToggleButtonLoaderChanged.next(this.loadingButtons);
        }
      }
  }


  exportToExcel(title, type, fn?, dl?) {
    let elt = document.getElementById('tbl_exporttable_to_xls');
    let wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
    return dl ?
      XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }):
      XLSX.writeFile(wb, fn || (title+'.' + (type || 'xlsx')));
  }
}

class FormValidation {
  constructor(public constraintType: string, public message: string) { }
}
