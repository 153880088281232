import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, pipe } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
@Injectable({
  providedIn: 'root'
})
export class SnackbarInterceptor implements HttpInterceptor {

  requestType = 'GET';
  swalMethods = ['POST', 'PATCH', 'PUT', 'DELETE'];

  constructor(private _snackBar: MatSnackBar, private _fuseSplashScreenService: FuseSplashScreenService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.requestType = req.method;

    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {

        const isSwal = this.swalMethods.find(method => method === this.requestType);
        // console.log({event});
        if (isSwal) {
          this.showSnackBar(event);
        }
      }
    },
      (err: any) => {
        // prevent Continuous loading on error
        this._fuseSplashScreenService.hide();

        this.catchError(err, event);
      }));
  }

  showSnackBar(event: any): void {

    switch (event.status) {
      case 200: // updated
        this.openSnackBarSuccess('Successful');
        break;
      case 201: // created
        this.openSnackBarSuccess('Saved Successfully');
        break;
      case 204: // deleted
        this.openSnackBarSuccess('Deleted Successfully');
        break;
      case 301: // 301 Moved Permanently 
        this.openSnackBarWarn('Update Requested Resource URL');
        break;
      case 408: // 408 Request Timeout
        this.openSnackBarWarn('Server took too long to respond');
        break;
      case 503: // 503 Service Unavailable 
        this.openSnackBarWarn('Server Not Responding');
        break;
      default:
        this.openSnackBarDefault(event.statusText);
        break;
    }

  }

  openSnackBarDefault(message: string, action?: string): void {
    this._snackBar.open(message, action, {
      panelClass: 'success',
      duration: 2000,
    });
  }

  openSnackBarSuccess(message: string, action?: string): void {
    this._snackBar.open(message, action, {
      panelClass: 'accent',
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom'
    });
  }

  openSnackBarWarn(message: string, action?: string): void {
    this._snackBar.open(message, action, {
      panelClass: 'warn',
      duration: 5000,
    });
  }

  openSnackBarError(message: string, action?: string): void {
    this._snackBar.open(message, action, {
      panelClass: 'warn',
      duration: 10000,
    });
  }



  catchError(err, event): void {
    console.error({ err }, { event });
    let title = 'Error occured';
    let description = 'server error';
    if (err.error && err.error['@type'] === 'hydra:Error') {
      title = err.error['hydra:title'];
      description = err.error['hydra:description'];
    }

    // notify developers err
    
    switch (err.status) {
      // case 404: // notFOund
      //   this.openSnackBarError('Content not found', err.status);
      //   break;
      case 410: // ForeignKeyConstraintViolationException:
        this.openSnackBarError(title + ': Integrity violation. Cannot delete, Item used in other tables', err.status);
        break;
      // 411: //UniqueConstraintViolationException:     

      case 0: // 0 Unknown Error 
        this.openSnackBarError('Server Unreachable, Check Your Internet Connection');
        break;
      case 301: // 301 Moved Permanently 
        this.openSnackBarWarn('Update Requested Resource URL');
        break;
      case 400: // 400 Bad Request 
        this.openSnackBarError('Invalid username and password combination');
        break;
      case 401: // 401 Unauthorized 
        this.openSnackBarError('Authentication Credentials Invalid');
        break;
      case 403: // 403 Forbidden 
        this.openSnackBarError('Access Denied');
        break;
      case 404: // 404 Not Found 
        this.openSnackBarError('Requested Resource Doesn\'t Exist');
        break;
      case 405: // 405 Method Not Allowed 
        this.openSnackBarError('Use Appropriate Request Method (GET,POST,PUT,PATCH,DELETE)');
        break;
      case 408: // 408 Request Timeout
        this.openSnackBarError('Server took too long to respond');
        break;
      case 500: // 500 Internal Server Error 
        this.openSnackBarError('Oops! Error occured. Try again or contact admin');
        break;
      case 503: // 503 Service Unavailable 
        this.openSnackBarError('Server Not Responding');
        break;
      
      default:
        // this.openSnackBarError(title + ': ' + err.message, err.status);
        this.openSnackBarError(err.status, title);
        break;
    }

  }
}
