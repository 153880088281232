// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr: false,
    PRIMAL_CLIENT_ID: '2_6cblow9ecmscsc4wo44w8cgwkggkwogcckwgwo4okwwc0840co',
    PRIMAL_CLIENT_SECRET: '329shz02zl0kg0wgk8soccowcc04oo8swswkwo0cgwwoks0csw',
    reportServerURL: "http://localhost:8080/jasperserver",
    // serverURL: "https://ec2-13-40-194-58.eu-west-2.compute.amazonaws.com:8000",
    serverURL: 'http://parsapi.us-east-1.elasticbeanstalk.com',
    // serverURL: "http://localhost:8080",
    authURI: "/oauth/v2/token",
    STORAGE_SECRET: "SEC1234567890",
    defaultAvatar: "/assets/images/avatars/profile.jpg",
    version: "V0.10",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
// http://192.168.10.100:8000/api/boardingpass?paxId=$P{paxId}&bookingLegId=$P{bookingLegId}
